<template>
  <div class="order-product">
    <van-cell v-for="item in list" :key="item.id" is-link class="item" @click="goFaceInfo(item.orderId)">
      <!-- 使用 title 插槽来自定义标题 -->
      <template #title>
        <span class="custom-title">{{ item.name }}</span>
        <span v-if="item.userStatus === 1" class="status">已上传</span>
      </template>
    </van-cell>
  </div>
</template>
<script>
import { getProductsByMainOrder } from '@/api/product'
export default {
  data() {
    return {
      list: [],
      orderId: ''
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId
    this.getList()
  },
  methods: {
    goFaceInfo(orderId) {
      this.$router.push({ path: '/chatFaceInfo', query: { orderId, time: new Date().getTime() }})
    },
    async getList() {
      const res = await getProductsByMainOrder({ orderId: this.orderId })
      this.list = res.datas
    }
  }
}
</script>
<style lang="scss" scoped>
.order-product {
  .item {
    .status {
      display: inline-block;
      width: 60px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
      color: #18C47C;
      border: 1px solid #18C47C;
      border-radius: 4px;
      margin-left: 10px
    }
  }
}
</style>
